import { CircularProgress, Grid } from "@mui/material";
import {
  DashboardStackCustom,
  StackCustom,
} from "components/StackCustom/styles";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { GraficoColuna } from "./components/GraficoColuna";
import { GraficoBarra } from "./components/GraficoBarra";
import { ListaDashBoard } from "./components/ListaDashboard";
import {
  getPorcentagemOrcamentosPorRegiao,
  getQuantidadeOrcamentosPorPeriodo,
  getAcabamentosMaisCalculadosEmOrcamentos,
  getClientesMaisUtilizaramProjetos,
} from "services/modules/dashboard/acabamentos";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { DashBoardAcabamentosDataType } from "shared/interfaces/dashboardAcabamentos";
import { ModalFiltroMesAno } from "components/ModalFiltroMesAno";
import { GraficoHeatMap } from "./components/GraficoHeatMap";
import { DashboardProps } from "shared/interfaces/dashboardSistemista";
import { ModalAcabMaisCalculados } from "./components/Modais/ModalAcabMaisCalculados";
import { ModalClientes } from "./components/Modais/ModalClientes";

function DashboardAcabamentos({
  modalDataFiltro,
  setModalDataFiltro,
  triggerUpdateDashboard,
  selectedRegion,
  setSelectedRegion,
}: DashboardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAcabMaisCalculadosOpen, setIsModalAcabMaisCalculadosOpen] =
    useState(false);
  const [isModalClientesOpen, setIsModalClientesOpen] = useState(false);

  const [dashBoardData, setDashboardData] =
    useState<DashBoardAcabamentosDataType>();

  const getDashBoardData = async (
    inicialData?: dayjs.Dayjs,
    finalData?: dayjs.Dayjs,
    regiaoSelecionada?: string,
    shouldShowLoading: boolean = true
  ) => {
    if (shouldShowLoading) setIsLoading(true);
    Promise.all([
      getPorcentagemOrcamentosPorRegiao(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getQuantidadeOrcamentosPorPeriodo(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getAcabamentosMaisCalculadosEmOrcamentos(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getClientesMaisUtilizaramProjetos(
        5,
        inicialData,
        finalData,
        regiaoSelecionada
      ),
    ])
      .then((res) => {
        setDashboardData({
          porcentagemOrcamentosPorRegiao: res[0],
          quantidadeOrcamentosPorPeriodo: res[1],
          acabamentosMaisCalculadosEmOrcamentos: res[2],
          clientesMaisUtilizaramProjetos: res[3],
        });
      })
      .catch((error) => {
        setDashboardData(undefined);
        getErrorsByApi(
          error,
          "Tivemos problemas ao buscar as informações do Dashboard"
        );
      })
      .finally(() => {
        if (shouldShowLoading) setIsLoading(false);
      });
  };

  const dadosAcabamentosMaisCalculadas = useMemo(() => {
    const labels: string[] = [];
    const data: number[] = [];
    let total = 0;
    dashBoardData?.acabamentosMaisCalculadosEmOrcamentos
      ?.slice(0, 5)
      .forEach((acabamento) => {
        labels.push(acabamento.codigo);
        data.push(acabamento.porcentagem);
        total += acabamento.porcentagem;
      });

    return {
      labels,
      series: data,
    };
  }, [dashBoardData?.acabamentosMaisCalculadosEmOrcamentos]);

  useEffect(() => {
    getDashBoardData(
      modalDataFiltro.inicialData,
      modalDataFiltro.finalData,
      selectedRegion ?? undefined
    );
  }, [triggerUpdateDashboard]);

  useEffect(() => {
    getDashBoardData(
      modalDataFiltro.inicialData,
      modalDataFiltro.finalData,
      selectedRegion ?? undefined,
      false
    );
  }, [selectedRegion]);

  return (
    <>
      <DashboardStackCustom>
        <Grid container mt={4} display={"flex"} alignItems={"center"}>
          {dashBoardData && !isLoading ? (
            <StackCustom>
              <Grid container>
                <Grid item xs={12}>
                  <GraficoHeatMap
                    title="% de orçamentos/obras calculados com meus produtos por região"
                    porcentagemOrcamentosPorRegiao={
                      dashBoardData.porcentagemOrcamentosPorRegiao
                    }
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                  />
                </Grid>
                <Grid item xs={12} mt={3}>
                  <GraficoColuna
                    yaxisLabel="N° de orçamentos/obras"
                    title="Quantidade de orçamentos/obras calculados com meus acabamentos por período"
                    series={[
                      {
                        name: String(
                          dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                            ? dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                                .anoAnterior
                            : parseInt(dayjs().format("YYYY")) - 1
                        ),
                        color: "#12629F",
                        data:
                          dashBoardData?.quantidadeOrcamentosPorPeriodo?.map(
                            (periodo) => {
                              return {
                                quantidade:
                                  periodo.quantidadeOrcamentosAnoAnterior,
                                mes: periodo.mesId,
                              };
                            }
                          ) ?? [],
                      },
                      {
                        name: String(
                          dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                            ? dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                                .anoAtual
                            : dayjs().format("YYYY")
                        ),
                        color: "#9F7812",
                        data:
                          dashBoardData?.quantidadeOrcamentosPorPeriodo?.map(
                            (periodo) => {
                              return {
                                quantidade:
                                  periodo.quantidadeOrcamentosAnoAtual,
                                mes: periodo.mesId,
                              };
                            }
                          ) ?? [],
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} mt={3}>
                  <GraficoBarra
                    title="Acabamentos mais calculados nos orçamentos/obras"
                    series={[
                      {
                        name: "%",
                        data: dadosAcabamentosMaisCalculadas.series,
                      },
                    ]}
                    categories={dadosAcabamentosMaisCalculadas.labels}
                    onClick={() => {
                      setIsModalAcabMaisCalculadosOpen(true);
                    }}
                  />
                </Grid>

                <Grid item xs={12} mt={3}>
                  <ListaDashBoard
                    onClick={() => {
                      setIsModalClientesOpen(true);
                    }}
                    columns={[
                      {
                        field: "ranking",
                        headerName: "",
                        sortable: false,
                        flex: 0.1,
                      },
                      {
                        field: "nome",
                        headerName: "Empresas que mais orçaram",
                        sortable: false,
                        flex: 0.5,
                      },
                      {
                        field: "cpfCnpj",
                        headerName: "CNPJ/CPF",
                        sortable: false,
                        flex: 0.35,
                      },
                      {
                        field: "cidade",
                        headerName: "Cidade",
                        sortable: false,
                        flex: 0.25,
                      },
                      {
                        field: "estado",
                        headerName: "Estado",
                        sortable: false,
                        flex: 0.25,
                      },
                    ]}
                    title="Clientes que mais utilizaram meus acabamentos"
                    dataRows={
                      dashBoardData?.clientesMaisUtilizaramProjetos
                        ?.slice(0, 5)
                        .map((cliente) => {
                          return {
                            ...cliente,
                            id: cliente.ranking,
                          };
                        })!
                    }
                  />
                </Grid>
              </Grid>
            </StackCustom>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </DashboardStackCustom>
      <ModalFiltroMesAno
        isOpen={modalDataFiltro.isOpen}
        onClose={() =>
          setModalDataFiltro({
            ...modalDataFiltro,
            isOpen: false,
          })
        }
        loading={isLoading}
        finalData={modalDataFiltro.finalData}
        inicialData={modalDataFiltro.inicialData}
        onSubmit={modalDataFiltro.onSubmit}
      />
      <ModalAcabMaisCalculados
        isOpen={isModalAcabMaisCalculadosOpen}
        onClose={() => setIsModalAcabMaisCalculadosOpen(false)}
        title="Acabementos mais calculadas nos orçamentos/obras"
        data={
          dashBoardData?.acabamentosMaisCalculadosEmOrcamentos?.map((acab) => {
            return {
              codigo: acab.codigo,
              porcentagem: acab.porcentagem,
              descricao: acab.descricao,
              tipos: acab.tipoMaterial,
            };
          }) ?? []
        }
      />
      <ModalClientes
        isOpen={isModalClientesOpen}
        onClose={() => setIsModalClientesOpen(false)}
        title="Clientes que mais utilizaram meus acabamentos"
        data={
          dashBoardData?.clientesMaisUtilizaramProjetos?.map((cliente) => {
            return {
              razaoSocial: cliente.nome,
              cnpj: cliente.cpfCnpj,
              estado: cliente.estado,
              cidade: cliente.cidade,
              porcentagemUso: cliente.porcentagemUso,
            };
          }) ?? []
        }
      />
    </>
  );
}

export default DashboardAcabamentos;
