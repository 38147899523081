import React from "react";
import { Map } from "react-map-gl/maplibre";
import DeckGL from "@deck.gl/react";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import { TextLayer } from "@deck.gl/layers";

import type { MapViewState } from "@deck.gl/core";
import { DataPoint } from "shared/interfaces/heatMap";

const INITIAL_VIEW_STATE: MapViewState = {
  longitude: -55.1379,
  latitude: -14.9225,
  zoom: 3.2,
  maxZoom: 16,
  pitch: 0,
  bearing: 0,
};

const MAP_STYLE =
  "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json";

export default function NewGrafico({
  data,
  intensity = 2,
  threshold = 0.03,
  radiusPixels = 30,
  mapStyle = MAP_STYLE,
}: {
  data?: DataPoint[];
  intensity?: number;
  threshold?: number;
  radiusPixels?: number;
  mapStyle?: string;
}) {
  const heatmapLayer = new HeatmapLayer<DataPoint>({
    id: "heatmap-layer",
    data,
    pickable: false,
    getPosition: (d) => [d.longitude, d.latitude],
    getWeight: (d) => d.count,
    radiusPixels,
    intensity,
    threshold,
  });

  const textLayer = new TextLayer({
    id: "text-layer",
    data,
    getPosition: (d) => [d.longitude, d.latitude],
    getText: (d) => String(d.count),
    getSize: 16,
    getColor: [0, 0, 0, 255],
    getTextAnchor: "middle",
    getAlignmentBaseline: "center",
  });

  return (
    <DeckGL
      initialViewState={INITIAL_VIEW_STATE}
      controller={true}
      layers={[heatmapLayer, textLayer]}
      style={{
        position: "relative",
        height: "600px",
        width: "810px",
        borderRadius: "20px",
        maxHeight: "600px",
        maxWidth: "850px",
      }}
    >
      <Map reuseMaps mapStyle={mapStyle} style={{ borderRadius: "20px" }} />
    </DeckGL>
  );
}
