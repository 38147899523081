import { CircularProgress, Grid } from "@mui/material";
import {
  DashboardStackCustom,
  StackCustom,
} from "components/StackCustom/styles";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { GraficoColuna } from "./components/GraficoColuna";
import { GraficoPizza } from "./components/GraficoPizza";
import { GraficoBarra } from "./components/GraficoBarra";
import { ListaDashBoard } from "./components/ListaDashboard";
import { ModalListaLinha } from "./components/Modais/ModalListaLinha";
import { ModalListaProjetos } from "./components/Modais/ModalListaProjetos";
import { ModalListaCompsSemAp } from "./components/Modais/ModalListaCompsSemAp";
import {
  getClientesMaisUtilizaramProjetos,
  getComponentesMaisCalculadosEmOrcamentos,
  getLinhasMaisCalculadosEmOrcamentos,
  getPerfisMaisCalculadosEmOrcamentos,
  getPorcentagemComponentesSemAplicabilidade,
  getPorcentagemOrcamentosPorRegiao,
  getProjetosMaisCalculadosEmOrcamentos,
  getQuantidadeOrcamentosPorPeriodo,
  getRankingTipologiasMaisUtilizadasEmOrcamentos,
} from "services/modules/dashboard/sistemista";
import {
  DashboardProps,
  DashBoardSistemistaDataType,
} from "shared/interfaces/dashboardSistemista";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ModalFiltroMesAno } from "components/ModalFiltroMesAno";
import { GraficoHeatMap } from "./components/GraficoHeatMap";
import { ModalClientes } from "./components/Modais/ModalClientes";
import { ModalListaComponentes } from "./components/Modais/ModalListaComponentes";
import { ModalPerfisMaisCalculados } from "./components/Modais/ModalPerfisMaisCalculados";

function DashboardSistemista({
  modalDataFiltro,
  triggerUpdateDashboard,
  setModalDataFiltro,
  setSelectedRegion,
  selectedRegion,
}: DashboardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalListaLinhasOpen, setIsModalListaLinhasOpen] = useState(false);
  const [isModalListaProjetosOpen, setIsModalListaProjetosOpen] =
    useState(false);

  const [isModalPerfisMaisCalculadosOpen, setIsModalPerfisMaisCalculadosOpen] =
    useState(false);
  const [isModalSemApOpen, setIsModalSemApOpen] = useState(false);
  const [isModalClientesOpen, setIsModalClientesOpen] = useState(false);
  const [isModalComponentesOpen, setIsModalComponentesOpen] = useState(false);

  const [dashBoardData, setDashboardData] =
    useState<DashBoardSistemistaDataType>();

  const getDashBoardData = async (
    inicialData?: dayjs.Dayjs,
    finalData?: dayjs.Dayjs,
    regiaoSelecionada?: string,
    shouldShowLoading: boolean = true
  ) => {
    if (shouldShowLoading) setIsLoading(true);
    Promise.all([
      getPorcentagemOrcamentosPorRegiao(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getQuantidadeOrcamentosPorPeriodo(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getLinhasMaisCalculadosEmOrcamentos(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getProjetosMaisCalculadosEmOrcamentos(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getPerfisMaisCalculadosEmOrcamentos(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getComponentesMaisCalculadosEmOrcamentos(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getClientesMaisUtilizaramProjetos(
        5,
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getPorcentagemComponentesSemAplicabilidade(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
      getRankingTipologiasMaisUtilizadasEmOrcamentos(
        inicialData,
        finalData,
        regiaoSelecionada
      ),
    ])
      .then((res) => {
        setDashboardData({
          porcentagemOrcamentosPorRegiao: res[0],
          quantidadeOrcamentosPorPeriodo: res[1],
          linhasMaisCalculadosEmOrcamentos: res[2],
          projetosMaisCalculadosEmOrcamentos: res[3],
          perfisMaisCalculadosEmOrcamentos: res[4],
          componentesMaisCalculadosEmOrcamentos: res[5],
          clientesMaisUtilizaramProjetos: res[6],
          porcentagemComponentesSemAplicabilidade: res[7],
          rankingTipologiasMaisUtilizadasEmOrcamentos: res[8],
        });
      })
      .catch((error) => {
        setDashboardData(undefined);
        getErrorsByApi(
          error,
          "Tivemos problemas ao buscar as informações do Dashboard"
        );
      })
      .finally(() => {
        if (shouldShowLoading) setIsLoading(false);
      });
  };

  const dadosLinhasMaisCalculadas = useMemo(() => {
    const labels: string[] = [];
    const data: number[] = [];
    let total = 0;
    dashBoardData?.linhasMaisCalculadosEmOrcamentos
      ?.slice(0, 5)
      .forEach((linha) => {
        labels.push(linha.linha);
        data.push(linha.porcentagem);
        total += linha.porcentagem;
      });

    return {
      labels,
      series: data,
    };
  }, [dashBoardData?.linhasMaisCalculadosEmOrcamentos]);

  const dadosProjetosMaisCalculados = useMemo(() => {
    const labels: string[] = [];
    const data: number[] = [];
    let total = 0;
    dashBoardData?.projetosMaisCalculadosEmOrcamentos
      ?.slice(0, 5)
      .forEach((projeto) => {
        labels.push(projeto.tipologia);
        data.push(projeto.porcentagem);
        total += projeto.porcentagem;
      });

    return {
      labels,
      series: data,
    };
  }, [dashBoardData?.projetosMaisCalculadosEmOrcamentos]);

  const dadosPerfisMaisCalculados = useMemo(() => {
    const labels: string[] = [];
    const data: number[] = [];
    let total = 0;
    dashBoardData?.perfisMaisCalculadosEmOrcamentos
      ?.slice(0, 5)
      .forEach((perfil) => {
        labels.push(perfil.codigo);
        data.push(perfil.porcentagem);
        total += perfil.porcentagem;
      });

    return {
      labels,
      series: data,
    };
  }, [dashBoardData?.perfisMaisCalculadosEmOrcamentos]);

  const dadosComponentesMaisCalculados = useMemo(() => {
    const labels: string[] = [];
    const data: number[] = [];
    let total = 0;
    dashBoardData?.componentesMaisCalculadosEmOrcamentos
      ?.slice(0, 5)
      .forEach((componente) => {
        labels.push(componente.codigo);
        data.push(componente.porcentagem);
        total += componente.porcentagem;
      });

    return {
      labels,
      series: data,
    };
  }, [dashBoardData?.componentesMaisCalculadosEmOrcamentos]);

  const dadosComponentesSemAplicabilidade = useMemo(() => {
    const labels: string[] = [];
    const data: number[] = [];
    let total = 0;
    dashBoardData?.porcentagemComponentesSemAplicabilidade
      ?.slice(0, 5)
      .forEach((componente) => {
        labels.push(componente.codigo);
        data.push(componente.porcentagem);
        total += componente.porcentagem;
      });

    return {
      labels,
      series: data,
    };
  }, [dashBoardData?.porcentagemComponentesSemAplicabilidade]);

  useEffect(() => {
    getDashBoardData(
      modalDataFiltro.inicialData,
      modalDataFiltro.finalData,
      selectedRegion ?? undefined
    );
  }, [triggerUpdateDashboard]);

  useEffect(() => {
    getDashBoardData(
      modalDataFiltro.inicialData,
      modalDataFiltro.finalData,
      selectedRegion ?? undefined,
      false
    );
  }, [selectedRegion]);

  return (
    <>
      <DashboardStackCustom>
        <Grid container mt={4} display={"flex"} alignItems={"center"}>
          {dashBoardData && !isLoading ? (
            <StackCustom>
              <Grid container>
                <Grid item xs={12}>
                  <GraficoHeatMap
                    title="% de orçamentos/obras calculados com meus projetos por região"
                    porcentagemOrcamentosPorRegiao={
                      dashBoardData.porcentagemOrcamentosPorRegiao ?? null
                    }
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                  />
                </Grid>
                <Grid item xs={12} mt={3}>
                  <GraficoColuna
                    yaxisLabel="N° de orçamentos/obras"
                    title="Quantidade de orçamentos/obras calculados com meus projetos por período"
                    series={[
                      {
                        name: String(
                          dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                            ? dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                                .anoAnterior
                            : parseInt(dayjs().format("YYYY")) - 1
                        ),
                        color: "#12629F",
                        data:
                          dashBoardData?.quantidadeOrcamentosPorPeriodo?.map(
                            (periodo) => {
                              return {
                                quantidade:
                                  periodo.quantidadeOrcamentosAnoAnterior,
                                mes: periodo.mesId,
                              };
                            }
                          ) ?? [],
                      },
                      {
                        name: String(
                          dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                            ? dashBoardData.quantidadeOrcamentosPorPeriodo?.[0]
                                .anoAtual
                            : dayjs().format("YYYY")
                        ),
                        color: "#9F7812",
                        data:
                          dashBoardData?.quantidadeOrcamentosPorPeriodo?.map(
                            (periodo) => {
                              return {
                                quantidade:
                                  periodo.quantidadeOrcamentosAnoAtual,
                                mes: periodo.mesId,
                              };
                            }
                          ) ?? [],
                      },
                    ]}
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} mt={3}>
                    <GraficoPizza
                      onClick={() => setIsModalListaLinhasOpen(true)}
                      title="Linhas mais calculadas nos orçamentos/obras"
                      labels={dadosLinhasMaisCalculadas.labels}
                      series={dadosLinhasMaisCalculadas.series}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mt={3}>
                    <GraficoPizza
                      onClick={() => setIsModalListaProjetosOpen(true)}
                      title="Projetos mais calculados nos orçamentos/obras"
                      labels={dadosProjetosMaisCalculados.labels}
                      series={dadosProjetosMaisCalculados.series}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mt={3}>
                    <GraficoBarra
                      onClick={() => setIsModalPerfisMaisCalculadosOpen(true)}
                      title="Perfis mais calculados nos orçamentos/obras"
                      series={[
                        {
                          name: "%",
                          data: dadosPerfisMaisCalculados.series,
                        },
                      ]}
                      categories={dadosPerfisMaisCalculados.labels}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mt={3}>
                    <GraficoBarra
                      onClick={() => setIsModalComponentesOpen(true)}
                      title="Componentes mais calculados nos orçamentos/obras"
                      series={[
                        {
                          name: "%",
                          data: dadosComponentesMaisCalculados.series,
                        },
                      ]}
                      categories={dadosComponentesMaisCalculados.labels}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} mt={3}>
                    <ListaDashBoard
                      onClick={() => {
                        setIsModalClientesOpen(true);
                      }}
                      columns={[
                        {
                          field: "ranking",
                          headerName: "",
                          sortable: false,
                          flex: 0.1,
                        },
                        {
                          field: "nome",
                          headerName: "Empresas que mais orçaram",
                          sortable: false,
                          flex: 0.5,
                        },
                        {
                          field: "cpfCnpj",
                          headerName: "CNPJ/CPF",
                          sortable: false,
                          flex: 0.35,
                        },
                        {
                          field: "cidade",
                          headerName: "Cidade",
                          sortable: false,
                          flex: 0.25,
                        },
                        {
                          field: "estado",
                          headerName: "Estado",
                          sortable: false,
                          flex: 0.25,
                        },
                      ]}
                      title="Clientes que mais utilizaram meus projetos"
                      dataRows={
                        dashBoardData?.clientesMaisUtilizaramProjetos
                          ?.slice(0, 5)
                          .map((cliente) => {
                            return {
                              ...cliente,
                              id: cliente.ranking,
                            };
                          })!
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={5.1} mt={3}>
                    <GraficoPizza
                      onClick={() => setIsModalSemApOpen(true)}
                      title="% de componentes sem aplicabilidades"
                      labels={dadosComponentesSemAplicabilidade.labels}
                      series={dadosComponentesSemAplicabilidade.series}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </StackCustom>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </DashboardStackCustom>
      <ModalFiltroMesAno
        isOpen={modalDataFiltro.isOpen}
        onClose={() =>
          setModalDataFiltro({
            ...modalDataFiltro,
            isOpen: false,
          })
        }
        loading={isLoading}
        finalData={modalDataFiltro.finalData}
        inicialData={modalDataFiltro.inicialData}
        onSubmit={modalDataFiltro.onSubmit}
      />
      <ModalListaLinha
        isOpen={isModalListaLinhasOpen}
        onClose={() => setIsModalListaLinhasOpen(false)}
        title="Linhas mais calculadas nos orçamentos/obras"
        data={
          dashBoardData?.linhasMaisCalculadosEmOrcamentos?.map((linha) => {
            return {
              sistema: linha.linha,
              percentual: linha.porcentagem,
            };
          }) ?? []
        }
      />
      <ModalListaProjetos
        isOpen={isModalListaProjetosOpen}
        onClose={() => setIsModalListaProjetosOpen(false)}
        title="Projetos mais calculadas nos orçamentos/obras"
        data={
          dashBoardData?.projetosMaisCalculadosEmOrcamentos?.map((projeto) => {
            return {
              codigo: projeto.tipologia,
              descricao: projeto.descricao,
              projetista: projeto.sistemista,
              linha: projeto.linha,
            };
          }) ?? []
        }
      />
      <ModalPerfisMaisCalculados
        isOpen={isModalPerfisMaisCalculadosOpen}
        onClose={() => setIsModalPerfisMaisCalculadosOpen(false)}
        title="Perfis mais calculados nos orçamentos/obras"
        data={
          dashBoardData?.perfisMaisCalculadosEmOrcamentos?.map((perfil) => {
            return {
              codigo: perfil.codigo,
              percentual: perfil.porcentagem,
            };
          }) ?? []
        }
      />
      <ModalListaCompsSemAp
        isOpen={isModalSemApOpen}
        onClose={() => setIsModalSemApOpen(false)}
        title="% de componentes sem aplicabilidades"
        data={
          dashBoardData?.porcentagemComponentesSemAplicabilidade?.map(
            (comp) => {
              return {
                codigo: comp.codigo,
                sistemista: "",
                linha: "",
                semAp: comp.porcentagem,
              };
            }
          ) ?? []
        }
      />
      <ModalClientes
        isOpen={isModalClientesOpen}
        onClose={() => setIsModalClientesOpen(false)}
        title="Clientes que mais utilizaram meus projetos"
        data={
          dashBoardData?.clientesMaisUtilizaramProjetos?.map((cliente) => {
            return {
              razaoSocial: cliente.nome,
              cnpj: cliente.cpfCnpj,
              estado: cliente.estado,
              cidade: cliente.cidade,
              porcentagemUso: cliente.porcentagemUso,
            };
          }) ?? []
        }
      />
      <ModalListaComponentes
        isOpen={isModalComponentesOpen}
        onClose={() => setIsModalComponentesOpen(false)}
        title="Componentes mais calculados nos orçamentos/obras"
        data={
          dashBoardData?.componentesMaisCalculadosEmOrcamentos?.map((comp) => {
            return {
              codigo: comp.codigo,
              porcentagemUso: comp.porcentagem,
              descricao: comp.descricao,
            };
          }) ?? []
        }
      />
    </>
  );
}

export default DashboardSistemista;
