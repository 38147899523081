import { LoadingButton } from "@mui/lab";
import { Box, FormControl, Grid } from "@mui/material";
import { PreventExit } from "components/PreventExit";
import { useNavigation } from "contexts/notificationsContext";
import { usePreventExit } from "hooks/usePreventExit";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  getEmpresaById,
  getListarPorTipoMaterial,
  postEmpresasDadosCadastrais,
} from "services/modules/empresas";
import { IPostEmpresasDadosCadastrais } from "shared/interfaces/empresa";
import { DadosCadastraisForm } from "shared/interfaces/formEmpresa";

import { Save } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { DataNotFound } from "components/DataNotFound";
import Select from "react-select";
import { FSelect } from "shared/interfaces/select";
import { fontsSizes } from "styles/theme";
import { CadastroEmpresaContext } from "contexts/cadastroEmpresaContext";
import { getBrasiliaTime } from "shared/helpers/cadastroEmpresas";

export const SEARCH_BAR_EMPRESA_ID = "search-bar-componente-anchor";

export type AplicabilidadesForm = {
  selectReferenciasBase: FSelect[];
  selectReferenciasAplicaveis: FSelect[];
  selectReferenciasProprias: FSelect[];
};

type FormTabelaPreco = {
  tipoSelecionados: Record<number, FSelect[]>;
};

export const AplicabilidadesForm = () => {
  const [tipoSelecionados, setTipoSelecionados] = useState<
    Record<number, FSelect[]>
  >(
    Array.from(
      {
        length: 5,
      },
      () => []
    )
  );

  const { selectedItems } = useContext(CadastroEmpresaContext);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    setError,
    getValues,
    clearErrors,
    formState,
    ...methods
  } = useForm<DadosCadastraisForm>({
    mode: "onBlur",
  });

  const formTabPreco = useForm<FormTabelaPreco>();

  const formAplicabilidades = useForm<AplicabilidadesForm>({
    defaultValues: {
      selectReferenciasBase: [],
      selectReferenciasAplicaveis: [],
      selectReferenciasProprias: [],
    },
  });

  const formStateAplicabilidades = useFormState({
    control: formAplicabilidades.control,
  });

  const { errors, isDirty, dirtyFields } = useFormState({ control });

  const { setDataUltimaAtualizacao } = useContext(CadastroEmpresaContext);
  const {
    isOpenModalUnsaved,
    handleIsOpenModalUnsaved,
    handleLinkNavigateBlocked,
    handleOnCloseModalUnsavedEdit,
    linkNavigateBlocked,
    isGoBack,
  } = usePreventExit(isDirty && Object.values(dirtyFields).length > 0);

  const params = useParams();
  const { openNotification } = useNavigation();

  const [checkLiberacao, setCheckLiberacao] = useState(false);
  const [checkImportar, setCheckImportar] = useState(false);
  const [checkExportar, setCheckExportar] = useState(false);

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [mappedIdReferencias, setMappedIdReferencias] =
    useState<Record<number, string>>();

  const [tiposReferencias, setTiposReferencias] = useState<FSelect[]>([]);

  const [referenciasBase, setReferenciasBase] = useState<FSelect[]>([]);
  const [referenciasAplicaveis, setReferenciasAplicaveis] = useState<FSelect[]>(
    []
  );
  const [referenciasProprias, setReferenciasProprias] = useState<FSelect[]>([]);

  const [selectedLiberacoes, setSelectedLiberacoes] = useState<Set<number>>(
    new Set()
  );
  const [selectedItemsRef, setSelectedItemsRef] = useState<number[]>([]);
  const [selectedItemsProj, setSelectedItemsProj] = useState<number[]>([]);

  const [selectedDashboards, setSelectedDashboards] = useState<Set<number>>(
    new Set()
  );

  const [accordionSelected, setAccordionSelected] = useState<number[]>([]);

  const [selectedTabela, setSelectedTabela] = useState<Set<number>>(new Set());

  async function getDadosEmpresas(idEmpresa: number) {
    try {
      const result = await getEmpresaById(idEmpresa);

      if (result) {
        const produtoNivelUmMaterialVMs =
          result.data.produtoNivelUmVM?.produtoNivelUmMaterialVMs;
        const newSelectedLiberacoes = new Set(selectedLiberacoes);

        produtoNivelUmMaterialVMs?.forEach((p) => {
          newSelectedLiberacoes.add(p.idTipoMaterial ?? 0);
        });
        setSelectedLiberacoes(newSelectedLiberacoes);

        //tabela
        const tabelaGetVMs = result.data.tabelaPrecoVMs;
        const newSelectedtabela = new Set(selectedTabela);

        tabelaGetVMs?.forEach((p) => {
          newSelectedtabela.add(p.idTipoMaterial ?? 0);
        });
        setSelectedTabela(newSelectedtabela);
        //tabela

        reset({
          referenciasAplicabilidadeVM: {
            idsReferenciasCodigoAplicavel:
              result.data.referenciasAplicabilidadeVM
                ?.idsReferenciasCodigoAplicavel,
            idsReferenciasCodigoBase:
              result.data.referenciasAplicabilidadeVM?.idsReferenciasCodigoBase,
            idsReferenciasCodigoProprio:
              result.data.referenciasAplicabilidadeVM
                ?.idsReferenciasCodigoProprio,
          },
          cepEmpresa: result.data.cep,
          complementoEmpresa: result.data.complemento,
          idCidadeEmpresa: result.data.idCidade ?? undefined,
          idEstadoEmpresa: result.data.idEstado ?? undefined,
          documentoEmpresa: result.data.cpfCnpj,
          enderecoEmpresa: result.data.endereco,
          nomeFantasiaEmpresa: result.data.nomeFantasia,
          numeroEmpresa: result.data.numero ?? undefined,
          idPerfilEmpresa: result.data.idPerfil,
          razaoSocialEmpresa: result.data.razaoSocial,
          ativoEmpresa: result.data.ativo,
          perfilEmpresa: result.data.idPerfil.toString(),
          tipoDashboardVMs: result.data.tipoDashboardVMs,
          produtoNivelUmVM: result.data.produtoNivelUmVM,
          abasDoPerfilSelecionado:
            result?.data.abasUtilizadasPerfil.map((item) => ({
              value: item.id,
              label: item.nome,
            })) ?? [],
          abasParaAdicionar:
            result?.data.abasDisponiveisRecursosAdicionais.map((item) => ({
              value: item.id,
              label: item.nome,
            })) ?? [],
        });
        setCheckLiberacao(
          result.data.produtoNivelUmVM?.apenasConsulta || false
        );
        setCheckImportar(
          result.data.produtoNivelUmVM?.importarReferenciaMercado || false
        );
        setCheckExportar(
          result.data.produtoNivelUmVM?.exportarReferenciaMercado || false
        );

        // TABELA
        const newTiposSelecionadosTabela = {
          ...formTabPreco.getValues("tipoSelecionados"),
        };
        tabelaGetVMs?.forEach((p) => {
          newTiposSelecionadosTabela[p.idTipoMaterial ?? 0] =
            p.idsReferencia?.map((ref) => {
              return {
                value: ref,
                label: mappedIdReferencias?.[ref] ?? "",
              };
            }) ?? [];
        });
        formTabPreco.setValue("tipoSelecionados", newTiposSelecionadosTabela);

        const newAccordionSelectedTabela: number[] = [];
        result.data?.tabelaPrecoVMs?.forEach((item) => {
          if (item) {
            newAccordionSelectedTabela.push(item?.idTipoMaterial);
          }
        });
        setAccordionSelected(newAccordionSelectedTabela);
        // TABELA

        formAplicabilidades.setValue(
          "selectReferenciasBase",
          result?.data.referenciasAplicabilidadeVM?.idsReferenciasCodigoBase?.map(
            (ref) => ({
              value: ref,
              label: mappedIdReferencias?.[ref] ?? "",
            })
          ) ?? []
        );

        formAplicabilidades.setValue(
          "selectReferenciasAplicaveis",
          result?.data.referenciasAplicabilidadeVM?.idsReferenciasCodigoAplicavel?.map(
            (ref) => ({
              value: ref,
              label: mappedIdReferencias?.[ref] ?? "",
            })
          ) ?? []
        );

        formAplicabilidades.setValue(
          "selectReferenciasProprias",
          result?.data.referenciasAplicabilidadeVM?.idsReferenciasCodigoProprio?.map(
            (ref) => ({
              value: ref,
              label: mappedIdReferencias?.[ref] ?? "",
            })
          ) ?? []
        );

        setTipoSelecionados((prev) => {
          const newTiposSelecionados = { ...prev };
          produtoNivelUmMaterialVMs?.forEach((p) => {
            newTiposSelecionados[p.idTipoMaterial ?? 0] =
              p.idsReferencia?.map((ref) => {
                return {
                  value: ref,
                  label: mappedIdReferencias?.[ref] ?? "",
                };
              }) ?? [];
          });
          return newTiposSelecionados;
        });
      }

      if (result && result.data) {
        const tipoMaterialVMs =
          result.data.produtoNivelUmVM?.produtoNivelUmMaterialVMs || [];

        const referenciaItems = tipoMaterialVMs.flatMap(
          (item) => item.idsReferencia || []
        );

        setSelectedItemsRef(referenciaItems);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    }
  }

  const handleSubmitDadosCadastrais = async (data: DadosCadastraisForm) => {
    setIsLoadingSubmit(true);
    try {
      const produtoNivelUmMaterialVMs = Array.from(selectedLiberacoes).map(
        (idTipoMaterial) => {
          const nomesReferencia = tipoSelecionados[idTipoMaterial];
          const idsReferencia = nomesReferencia.map((item) => {
            return item.value;
          });

          return {
            idTipoMaterial,
            idsReferencia,
          };
        }
      );

      const tabelaVMs = Array.from(selectedTabela).map((idTipoMaterial) => {
        const nomesReferencia =
          formTabPreco.getValues("tipoSelecionados")[idTipoMaterial];
        const idsReferencia = nomesReferencia.map((item) => {
          return item.value;
        });

        return {
          idTipoMaterial,
          idsReferencia,
        };
      });

      const transformToEmpresa = (
        data: DadosCadastraisForm
      ): IPostEmpresasDadosCadastrais => {
        return {
          idEmpresa: params.id ? Number(params.id) : 0,
          nomeFantasia: data.nomeFantasiaEmpresa,
          cpfCnpj: data.documentoEmpresa,
          cep: data.cepEmpresa,
          ativo: data.ativoEmpresa ?? false,
          complemento: data?.complementoEmpresa ?? "",
          endereco: data.enderecoEmpresa,
          idCidade: data.idCidadeEmpresa ?? null,
          idEstado: data.idEstadoEmpresa ?? null,
          idPerfil: data.idPerfilEmpresa,
          numero: data.numeroEmpresa ?? null,
          razaoSocial: data.razaoSocialEmpresa,
          abas: selectedItems,
          produtoNivelUmVM: data.produtoNivelUmVM,
          tipoDashboardVMs: data.tipoDashboardVMs,
          tabelaPrecoVMs: tabelaVMs,
          referenciasAplicabilidadeVM: {
            idsReferenciasCodigoAplicavel: formAplicabilidades
              .getValues("selectReferenciasAplicaveis")
              .map((item) => item.value),
            idsReferenciasCodigoBase: formAplicabilidades
              .getValues("selectReferenciasBase")
              .map((item) => item.value),
            idsReferenciasCodigoProprio: formAplicabilidades
              .getValues("selectReferenciasProprias")
              .map((item) => item.value),
          },
          dataUltimaAtualizacao: getBrasiliaTime(),
        };
      };

      const postData: IPostEmpresasDadosCadastrais = transformToEmpresa(data);

      if (params.id) {
        await postEmpresasDadosCadastrais(postData);
        openNotification({
          isOpen: true,
          message: "Empresa atualizada com sucesso!",
          type: "success",
        });
        formAplicabilidades.reset({
          selectReferenciasAplicaveis: formAplicabilidades.getValues(
            "selectReferenciasAplicaveis"
          ),
          selectReferenciasBase: formAplicabilidades.getValues(
            "selectReferenciasBase"
          ),
          selectReferenciasProprias: formAplicabilidades.getValues(
            "selectReferenciasProprias"
          ),
        });
        return;
      }

      const result = await postEmpresasDadosCadastrais(postData);

      if (result) {
        openNotification({
          isOpen: true,
          message: "Empresa cadastrada com sucesso!",
          type: "success",
        });
      }
      formAplicabilidades.reset({
        selectReferenciasAplicaveis: formAplicabilidades.getValues(
          "selectReferenciasAplicaveis"
        ),
        selectReferenciasBase: formAplicabilidades.getValues(
          "selectReferenciasBase"
        ),
        selectReferenciasProprias: formAplicabilidades.getValues(
          "selectReferenciasProprias"
        ),
      });
      setDataUltimaAtualizacao(new Date());
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const fecthListarPorTiposMaterias = async () => {
    try {
      const result = await getListarPorTipoMaterial();

      if (result) {
        setMappedIdReferencias((prev) => {
          const newMappedRefs = { ...prev };
          result.forEach((ref) => (newMappedRefs[ref.value] = ref.label));
          return newMappedRefs;
        });
        setTiposReferencias(result);
        setShouldGetEmpresa(true);
        setReferenciasBase(result);
        setReferenciasAplicaveis(result);
        setReferenciasProprias(result);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: "Erro ao listar por tipos de materiais.",
        type: "error",
      });
    }
  };

  const [shouldGetEmpresa, setShouldGetEmpresa] = useState(false);

  useEffect(() => {
    fecthListarPorTiposMaterias();
  }, []);

  useEffect(() => {
    shouldGetEmpresa && params.id && getDadosEmpresas(Number(params.id));
  }, [params.id, shouldGetEmpresa]);

  return (
    <PreventExit
      handleIsOpenModalUnsaved={handleIsOpenModalUnsaved}
      handleLinkNavigateBlocked={handleLinkNavigateBlocked}
      isOpenModalUnsaved={isOpenModalUnsaved}
      handleOnCloseModalUnsavedEdit={handleOnCloseModalUnsavedEdit}
      linkNavigateBlocked={linkNavigateBlocked}
      isGoBack={isGoBack}
    >
      <FormProvider
        {...{
          control,
          handleSubmit,
          reset,
          setValue,
          watch,
          setError,
          getValues,
          clearErrors,
          formState,
          ...methods,
        }}
      >
        <Grid container p={1.5}>
          <Grid md={12}>
            {tiposReferencias.length === 0 ? (
              <Grid
                sx={{
                  minHeight: "60vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DataNotFound visible />
              </Grid>
            ) : (
              <>
                <Grid container spacing={3} mb={3} display={"flex"}>
                  <Grid item md={11.8}>
                    <FormControl sx={{ m: 1, width: "100%" }}>
                      <Typography
                        sx={{
                          color: "secondary",
                          fontSize: fontsSizes.medium,
                        }}
                      >
                        Referências dos Códigos Base:
                      </Typography>
                      <Select
                        isClearable={false}
                        isMulti
                        maxMenuHeight={290}
                        menuPosition="fixed"
                        options={referenciasBase}
                        value={formAplicabilidades.watch(
                          "selectReferenciasBase"
                        )}
                        onChange={(selectedOptions) => {
                          formAplicabilidades.setValue(
                            "selectReferenciasBase",
                            selectedOptions as FSelect[],
                            { shouldDirty: true }
                          );
                        }}
                        placeholder="Selecione o Código Base"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3} mb={3} display={"flex"}>
                  <Grid item md={11.8}>
                    <FormControl sx={{ m: 1, width: "100%" }}>
                      <Typography
                        sx={{
                          color: "secondary",
                          fontSize: fontsSizes.medium,
                        }}
                      >
                        Referências dos Códigos Aplicáveis:
                      </Typography>
                      <Select
                        isClearable={false}
                        isMulti
                        maxMenuHeight={290}
                        menuPosition="fixed"
                        options={referenciasAplicaveis}
                        value={formAplicabilidades.watch(
                          "selectReferenciasAplicaveis"
                        )}
                        onChange={(selectedOptions) => {
                          formAplicabilidades.setValue(
                            "selectReferenciasAplicaveis",
                            selectedOptions as FSelect[],
                            { shouldDirty: true }
                          );
                        }}
                        placeholder="Selecione o Código Aplicável"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3} mb={3} display={"flex"}>
                  <Grid item md={11.8}>
                    <FormControl sx={{ m: 1, width: "100%" }}>
                      <Typography
                        sx={{
                          color: "secondary",
                          fontSize: fontsSizes.medium,
                        }}
                      >
                        Referência do Código Próprio:
                      </Typography>
                      <Select
                        isClearable={false}
                        maxMenuHeight={290}
                        menuPosition="fixed"
                        isMulti
                        options={referenciasProprias}
                        value={formAplicabilidades.watch(
                          "selectReferenciasProprias"
                        )}
                        onChange={(selectedOptions) => {
                          formAplicabilidades.setValue(
                            "selectReferenciasProprias",
                            selectedOptions as FSelect[],
                            { shouldDirty: true }
                          );
                        }}
                        placeholder="Selecione o Código Próprio"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {tiposReferencias.length === 0 ? (
            <Grid></Grid>
          ) : (
            <Grid item md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  endIcon={<Save />}
                  onClick={handleSubmit(handleSubmitDadosCadastrais)}
                  loading={isLoadingSubmit}
                  disabled={!formStateAplicabilidades.isDirty}
                  sx={{
                    marginLeft: 1,
                  }}
                >
                  Salvar
                </LoadingButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </PreventExit>
  );
};
