import { useMemo } from "react";
import Chart from "react-apexcharts";
import { ContainerGrafico } from "../ContainerGrafico";
import { Serie } from "../GraficoBarra";

interface GraficoBarraEmpilhadaProps {
  title: string;
  categories: string[];
  series: Serie[];
  onClick?: () => void;
}

export function GraficoBarraEmpilhada({
  title,
  categories,
  series,
  onClick,
}: GraficoBarraEmpilhadaProps) {
  const options: ApexCharts.ApexOptions = useMemo(() => {
    return {
      series,
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " Itens";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    };
  }, []);

  return (
    <ContainerGrafico title={title} onClick={onClick}>
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={350}
      />
    </ContainerGrafico>
  );
}
