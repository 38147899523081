import dayjs from "dayjs";
import { apiDashboard } from "services/axios/api";
import { parseDate } from "shared/helpers/dashboard";
import { getErrorsByApi } from "shared/helpers/getErrorsByApi";
import { ApiResponse } from "shared/interfaces/api";
import {
  ListaClientesMaisUtilizaramProjetos,
  ListaOrcamentoPorRegiao,
  ListaOrcamentosPorPeriodo,
} from "shared/interfaces/dashboardSistemista";
import {
  ListaTipoVidrosMaisCalculadosEmOrcamentos,
  TipoMaterialMaisCalculadoCategorias,
  ListaVidrosMaisCalculadosEmOrcamentos,
} from "shared/interfaces/dashboardVidros";

export async function getPorcentagemOrcamentosPorRegiao(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaOrcamentoPorRegiao> =
      await apiDashboard.get(
        "/FabricanteVidro/ListarPorcentagemOrcamentosPorRegiao",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getQuantidadeOrcamentosPorPeriodo(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaOrcamentosPorPeriodo> =
      await apiDashboard.get(
        "/FabricanteVidro/ListarQuantidadeOrcamentosPorPeriodo",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getTipoVidrosMaisCalculadosEmOrcamentosViaCategoriasESeries(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<TipoMaterialMaisCalculadoCategorias> =
      await apiDashboard.get(
        "/FabricanteVidro/ListarTipoVidrosMaisCalculadosEmOrcamentosViaCategoriasESeries",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getTipoVidrosMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaTipoVidrosMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/FabricanteVidro/ListarTipoVidrosMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getVidrosMaisCalculadosEmOrcamentos(
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaVidrosMaisCalculadosEmOrcamentos> =
      await apiDashboard.get(
        "/FabricanteVidro/ListarVidrosMaisCalculadosEmOrcamentos",
        {
          params: dates
            ? {
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : undefined,
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}

export async function getClientesMaisUtilizaramProjetos(
  qtdRanking: number,
  inicialData?: dayjs.Dayjs,
  finalData?: dayjs.Dayjs,
  regiaoSelecionada?: string
) {
  try {
    const dates = parseDate(inicialData, finalData);
    const response: ApiResponse<ListaClientesMaisUtilizaramProjetos> =
      await apiDashboard.get(
        "/FabricanteVidro/ListarClientesMaisUtilizaramProjetos",
        {
          params: dates
            ? {
                qtdRanking,
                mesInicial: dates.mesInicial,
                anoInicial: dates.anoInicial,
                mesFinal: dates.mesFinal,
                anoFinal: dates.anoFinal,
                regiao: regiaoSelecionada,
              }
            : { qtdRanking },
        }
      );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.data.errors.Mensagens[0] ||
      "Tivemos problemas internos ao buscar informações do dashboard";

    getErrorsByApi(error, errorMessage);
  }
  return null;
}
