import { LoadingButton } from "@mui/lab";
import { Box, FormControl, Grid } from "@mui/material";
import { PreventExit } from "components/PreventExit";
import { useNavigation } from "contexts/notificationsContext";
import { usePreventExit } from "hooks/usePreventExit";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  getEmpresaById,
  getListarPorTabelaPreco,
  getListarPorTipoMaterial,
  postEmpresasDadosCadastrais,
} from "services/modules/empresas";
import {
  IGetListarPorTabelaPreco,
  IPostEmpresasDadosCadastrais,
} from "shared/interfaces/empresa";
import { DadosCadastraisForm } from "shared/interfaces/formEmpresa";

import { Save } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import AccordionsSwitch from "components/AccordionsSwitch";
import { BarScroll } from "components/BarScroll";
import { DataNotFound } from "components/DataNotFound";
import Select from "react-select";
import { FSelect } from "shared/interfaces/select";
import { fontsSizes } from "styles/theme";
import { getBrasiliaTime } from "shared/helpers/cadastroEmpresas";
import { TiposSelecionadosForm } from "./cadastrosN";

export const SEARCH_BAR_EMPRESA_ID = "search-bar-componente-anchor";

type FormTabelaPreco = {
  tipoSelecionados: Record<number, FSelect[]>;
};

export const TabelaPrecosForm = () => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    setError,
    getValues,
    clearErrors,
    formState,
    ...methods
  } = useForm<DadosCadastraisForm>({
    mode: "onBlur",
  });

  const formTabPreco = useForm<FormTabelaPreco>();

  const formStateTabPreco = useFormState({ control: formTabPreco.control });

  const { errors, isDirty, dirtyFields } = useFormState({ control });

  const {
    isOpenModalUnsaved,
    handleIsOpenModalUnsaved,
    handleLinkNavigateBlocked,
    handleOnCloseModalUnsavedEdit,
    linkNavigateBlocked,
    isGoBack,
  } = usePreventExit(isDirty && Object.values(dirtyFields).length > 0);

  const params = useParams();
  const { openNotification } = useNavigation();

  const [checkLiberacao, setCheckLiberacao] = useState(false);
  const [checkImportar, setCheckImportar] = useState(false);
  const [checkExportar, setCheckExportar] = useState(false);

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [nAccordions, setNAccordions] = useState(0);
  const [mappedAccordionIdReferencias, setMappedAccordionIdReferencias] =
    useState<Record<number, number>>();
  const [mappedIdReferencias, setMappedIdReferencias] =
    useState<Record<number, string>>();

  const [listaTipoMaterial, setListaTipoMaterial] = useState<FSelect[]>([]);
  const [idTipoMaterial, setIdTipoMaterial] = useState<FSelect[]>([]);
  const [idTipoMaterialLista, setIdTipoMaterialLista] = useState<FSelect[]>([]);
  const [tiposMateriais, setTiposMateriais] = useState<
    IGetListarPorTabelaPreco[]
  >([]);
  const [selectedTabela, setSelectedTabela] = useState<Set<number>>(new Set());
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedItemsRef, setSelectedItemsRef] = useState<number[]>([]);
  const [selectedLiberacoes, setSelectedLiberacoes] = useState<Set<number>>(
    new Set()
  );

  const formTiposSelecionados = useForm<TiposSelecionadosForm>({
    defaultValues: {
      tiposSelecionados: {},
      checkExportar: false,
      checkImportar: false,
      checkLiberar: false,
    },
  });

  const formStateTiposSelecionados = useFormState({
    control: formTiposSelecionados.control,
  });

  const [accordionSelected, setAccordionSelected] = useState<number[]>([]);

  const handleSwitchChange = (id: number, checked: boolean) => {
    const newSelectedLiberacoes = new Set(selectedTabela);

    if (checked) {
      newSelectedLiberacoes.add(id);
      setAccordionSelected((prevState) => [...prevState, id]);
    } else {
      const filteredSelecteds = accordionSelected.filter((item) => {
        return item !== id;
      });
      setAccordionSelected(filteredSelecteds);
      newSelectedLiberacoes.delete(id);

      const closeAccordion = {
        ...formTabPreco.getValues("tipoSelecionados"),
      };
      closeAccordion[id ?? 0] = [];
      formTabPreco.setValue("tipoSelecionados", closeAccordion, {
        shouldDirty: true,
      });
    }
    setSelectedTabela(newSelectedLiberacoes);
  };

  async function getDadosEmpresas(idEmpresa: number) {
    try {
      const result = await getEmpresaById(idEmpresa);

      if (result) {
        const produtoNivelUmMaterialVMs =
          result.data.produtoNivelUmVM?.produtoNivelUmMaterialVMs;
        const newSelectedLiberacoes = new Set(selectedTabela);
        produtoNivelUmMaterialVMs?.forEach((p) => {
          newSelectedLiberacoes.add(p.idTipoMaterial ?? 0);
        });
        setSelectedTabela(newSelectedLiberacoes);

        const tabelaGetVMs = result.data.tabelaPrecoVMs;
        const newSelectedtabela = new Set(selectedTabela);
        tabelaGetVMs?.forEach((p) => {
          newSelectedtabela.add(p.idTipoMaterial ?? 0);
        });
        setSelectedTabela(newSelectedtabela);

        reset({
          cepEmpresa: result.data.cep,
          complementoEmpresa: result.data.complemento,
          idCidadeEmpresa: result.data.idCidade ?? undefined,
          idEstadoEmpresa: result.data.idEstado ?? undefined,
          documentoEmpresa: result.data.cpfCnpj,
          enderecoEmpresa: result.data.endereco,
          nomeFantasiaEmpresa: result.data.nomeFantasia,
          numeroEmpresa: result.data.numero ?? undefined,
          idPerfilEmpresa: result.data.idPerfil,
          razaoSocialEmpresa: result.data.razaoSocial,
          ativoEmpresa: result.data.ativo,
          perfilEmpresa: result.data.idPerfil.toString(),
          referenciasAplicabilidadeVM: result.data.referenciasAplicabilidadeVM,
          produtoNivelUmVM: result.data.produtoNivelUmVM,
          abasDoPerfilSelecionado:
            result?.data.abasUtilizadasPerfil.map((item) => ({
              value: item.id,
              label: item.nome,
            })) ?? [],
          abasParaAdicionar:
            result?.data.abasDisponiveisRecursosAdicionais.map((item) => ({
              value: item.id,
              label: item.nome,
            })) ?? [],
          tipoDashboardVMs: result.data.tipoDashboardVMs,
        });

        setSelectedItems(
          result.data.abasUtilizadasRecursosAdicionais.map((item) => item.id)
        );
        setCheckLiberacao(
          result.data.produtoNivelUmVM?.apenasConsulta || false
        );
        setCheckImportar(
          result.data.produtoNivelUmVM?.importarReferenciaMercado || false
        );
        setCheckExportar(
          result.data.produtoNivelUmVM?.exportarReferenciaMercado || false
        );

        const newAccordionSelected: number[] = [];

        result.data.produtoNivelUmVM?.produtoNivelUmMaterialVMs?.forEach(
          (item) => {
            if (item.idTipoMaterial) {
              newAccordionSelected.push(item.idTipoMaterial);
            }
          }
        );

        setAccordionSelected(newAccordionSelected);

        const newTiposSelecionados = {
          ...formTiposSelecionados.getValues("tiposSelecionados"),
        };
        produtoNivelUmMaterialVMs?.forEach((p) => {
          newTiposSelecionados[p.idTipoMaterial ?? 0] =
            p.idsReferencia?.map((ref) => {
              return {
                value: ref,
                label: mappedIdReferencias?.[ref] ?? "",
              };
            }) ?? [];
        });
        formTiposSelecionados.setValue(
          "tiposSelecionados",
          newTiposSelecionados
        );

        const newTiposSelecionadosTabela = {
          ...formTabPreco.getValues("tipoSelecionados"),
        };
        tabelaGetVMs?.forEach((p) => {
          newTiposSelecionadosTabela[p.idTipoMaterial ?? 0] =
            p.idsReferencia?.map((ref) => {
              return {
                value: ref,
                label: mappedIdReferencias?.[ref] ?? "",
              };
            }) ?? [];
        });
        formTabPreco.setValue("tipoSelecionados", newTiposSelecionadosTabela);
        const newAccordionSelectedTabela: number[] = [];
        result.data?.tabelaPrecoVMs?.forEach((item) => {
          if (item) {
            newAccordionSelectedTabela.push(item?.idTipoMaterial);
          }
        });
        setAccordionSelected(newAccordionSelectedTabela);
      }
      if (result && result.data) {
        const tipoMaterialVMs =
          result.data.produtoNivelUmVM?.produtoNivelUmMaterialVMs || [];

        const referenciaItems = tipoMaterialVMs.flatMap(
          (item) => item.idsReferencia || []
        );

        const selectedIds = new Set<number>(
          tipoMaterialVMs
            .map((item) => item.idTipoMaterial)
            .filter((id): id is number => id !== undefined)
        );
        setSelectedLiberacoes(selectedIds);

        setSelectedItemsRef(referenciaItems);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    }
  }

  const handleSubmitDadosCadastrais = async (data: DadosCadastraisForm) => {
    if (selectedTabela.size > 0) {
      const hasMissingReferences = Array.from(selectedTabela).some(
        (idTipoMaterial) => {
          const nomesReferencia =
            formTabPreco.getValues("tipoSelecionados")[idTipoMaterial];
          return !nomesReferencia || nomesReferencia.length === 0;
        }
      );

      if (hasMissingReferences) {
        openNotification({
          isOpen: true,
          message:
            "Por favor, adicione pelo menos uma referência para cada liberação selecionada.",
          type: "error",
        });
        return;
      }
    }

    setIsLoadingSubmit(true);
    try {
      const produtoNivelUmMaterialVMs = Array.from(selectedLiberacoes).map(
        (idTipoMaterial) => {
          const nomesReferencia =
            formTiposSelecionados.getValues("tiposSelecionados")[
              idTipoMaterial
            ];
          const idsReferencia = nomesReferencia.map((item) => {
            return item.value;
          });

          return {
            idTipoMaterial,
            idsReferencia,
          };
        }
      );

      const tabelaVMs = Array.from(selectedTabela).map((idTipoMaterial) => {
        const nomesReferencia =
          formTabPreco.getValues("tipoSelecionados")[idTipoMaterial];
        const idsReferencia = nomesReferencia.map((item) => {
          return item.value;
        });

        return {
          idTipoMaterial,
          idsReferencia,
        };
      });

      const transformToEmpresa = (
        data: DadosCadastraisForm
      ): IPostEmpresasDadosCadastrais => {
        return {
          idEmpresa: params.id ? Number(params.id) : 0,
          nomeFantasia: data.nomeFantasiaEmpresa,
          cpfCnpj: data.documentoEmpresa,
          cep: data.cepEmpresa,
          ativo: data.ativoEmpresa ?? false,
          complemento: data?.complementoEmpresa ?? "",
          endereco: data.enderecoEmpresa,
          idCidade: data.idCidadeEmpresa ?? null,
          idEstado: data.idEstadoEmpresa ?? null,
          idPerfil: data.idPerfilEmpresa,
          numero: data.numeroEmpresa ?? null,
          razaoSocial: data.razaoSocialEmpresa,
          abas: selectedItems,
          produtoNivelUmVM: {
            produtoNivelUmMaterialVMs: produtoNivelUmMaterialVMs,
            apenasConsulta: checkLiberacao,
            exportarReferenciaMercado: checkExportar,
            importarReferenciaMercado: checkImportar,
          },
          tipoDashboardVMs: data.tipoDashboardVMs,
          dataUltimaAtualizacao: getBrasiliaTime(),
          tabelaPrecoVMs: tabelaVMs,
          referenciasAplicabilidadeVM: data.referenciasAplicabilidadeVM,
        };
      };

      const postData: IPostEmpresasDadosCadastrais = transformToEmpresa(data);

      if (params.id) {
        await postEmpresasDadosCadastrais(postData);
        openNotification({
          isOpen: true,
          message: "Empresa atualizada com sucesso!",
          type: "success",
        });
        formTabPreco.reset({
          tipoSelecionados: formTabPreco.getValues("tipoSelecionados"),
        });
        return;
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: error?.message,
        type: "error",
      });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const accordionsTiposMaterial = async () => {
    try {
      const result = await getListarPorTabelaPreco();

      if (result) {
        const newMappedIdReferencias: Record<number, number> = {};
        result.forEach((r, index) => {
          newMappedIdReferencias[r.value] = index;
        });

        setMappedAccordionIdReferencias(newMappedIdReferencias);

        for (let i = 0; i < result.length; i++) {
          const idTipoMaterial = result[i].value ?? 0;
          await fecthListarPorTiposMaterias(
            idTipoMaterial,
            newMappedIdReferencias?.[i] ?? 0
          );
        }

        setShouldGetEmpresa(true);
        setNAccordions(result.length);
        formTabPreco.setValue(
          "tipoSelecionados",
          Array.from({ length: result.length }, () => [])
        );
        setTiposMateriais(
          result.map((item) => ({
            idTipoMaterial: item.value,
            descricao: item.label,
          }))
        );

        const tiposMateriaisSelect = result.map((item) => ({
          value: item.value,
          label: item.label,
        }));

        setIdTipoMaterial(tiposMateriaisSelect);
        setIdTipoMaterialLista(tiposMateriaisSelect);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: "Erro ao buscar tipos de materiais.",
        type: "error",
      });
    }
  };

  const fecthListarPorTiposMaterias = async (
    idTipoMaterial: number,
    index: number
  ) => {
    try {
      const result = await getListarPorTipoMaterial();
      if (result) {
        setMappedIdReferencias((prev) => {
          const newMappedRefs = { ...prev };
          result.forEach((ref) => (newMappedRefs[ref.value] = ref.label));
          return newMappedRefs;
        });
        setListaTipoMaterial(result);
        const newTiposSelecionados = {
          ...formTabPreco.getValues("tipoSelecionados"),
        };
        newTiposSelecionados[idTipoMaterial ?? 0] = [];
        formTabPreco.setValue("tipoSelecionados", newTiposSelecionados);
      }
    } catch (error: any) {
      openNotification({
        isOpen: true,
        message: "Erro ao listar por tipos de materiais.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    accordionsTiposMaterial();
  }, []);

  const [shouldGetEmpresa, setShouldGetEmpresa] = useState(false);

  useEffect(() => {
    shouldGetEmpresa && params.id && getDadosEmpresas(Number(params.id));
  }, [params.id, shouldGetEmpresa]);

  return (
    <PreventExit
      handleIsOpenModalUnsaved={handleIsOpenModalUnsaved}
      handleLinkNavigateBlocked={handleLinkNavigateBlocked}
      isOpenModalUnsaved={isOpenModalUnsaved}
      handleOnCloseModalUnsavedEdit={handleOnCloseModalUnsavedEdit}
      linkNavigateBlocked={linkNavigateBlocked}
      isGoBack={isGoBack}
    >
      <FormProvider
        {...{
          control,
          handleSubmit,
          reset,
          setValue,
          watch,
          setError,
          getValues,
          clearErrors,
          formState,
          ...methods,
        }}
      >
        <Grid container p={1.5}>
          <Grid md={12}>
            <>
              {tiposMateriais.length === 0 ? (
                <Grid></Grid>
              ) : (
                <Grid mb={2}>
                  <Typography
                    sx={{
                      fontSize: fontsSizes.mlarge,
                      color: "#3C90AD",
                      fontWeight: 600,
                    }}
                  >
                    Liberar Tabelas de Preços:
                  </Typography>
                </Grid>
              )}
            </>
            <BarScroll maxHeight={"50vh"}>
              {tiposMateriais.length === 0 ? (
                <Grid
                  sx={{
                    minHeight: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DataNotFound visible />
                </Grid>
              ) : (
                tiposMateriais.map((tipo, index) => (
                  <AccordionsSwitch
                    key={tipo.idTipoMaterial}
                    checked={selectedTabela.has(tipo.idTipoMaterial)}
                    onChangeSwtich={(e, checked) => {
                      handleSwitchChange(tipo.idTipoMaterial, checked);
                    }}
                    descricao={tipo.descricao}
                    id={tipo.idTipoMaterial}
                  >
                    <Grid container spacing={3} mb={3} display={"flex"}>
                      <Grid item md={11.8}>
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <Typography
                            sx={{
                              color: "secondary",
                              fontSize: fontsSizes.medium,
                            }}
                          >
                            Referências para tabelas de preços
                          </Typography>
                          <Select
                            isClearable={false}
                            maxMenuHeight={290}
                            menuPosition="fixed"
                            isMulti
                            key={index}
                            options={listaTipoMaterial}
                            value={
                              formTabPreco.watch("tipoSelecionados")[
                                tipo.idTipoMaterial
                              ]
                            }
                            isDisabled={
                              !selectedTabela.has(tipo.idTipoMaterial)
                            }
                            onChange={(selectedOptions) => {
                              const newSelected = selectedOptions
                                ? selectedOptions.map((item) =>
                                    typeof item === "string"
                                      ? { label: item, value: item }
                                      : item
                                  )
                                : [];
                              const newTiposSelecionados = {
                                ...formTabPreco.getValues("tipoSelecionados"),
                              };
                              newTiposSelecionados[tipo.idTipoMaterial ?? 0] =
                                newSelected;
                              formTabPreco.setValue(
                                "tipoSelecionados",
                                newTiposSelecionados,
                                { shouldDirty: true }
                              );
                            }}
                            placeholder="Selecione o tipo de material"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionsSwitch>
                ))
              )}
            </BarScroll>
          </Grid>

          {tiposMateriais.length === 0 ? (
            <Grid></Grid>
          ) : (
            <Grid item md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  endIcon={<Save />}
                  onClick={handleSubmit(handleSubmitDadosCadastrais)}
                  loading={isLoadingSubmit}
                  sx={{
                    marginLeft: 1,
                  }}
                  disabled={!formStateTabPreco.isDirty}
                >
                  Salvar
                </LoadingButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </PreventExit>
  );
};
