import { ModalLista } from "./ModalLista";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  data: {
    codigo: string;
    linha?: string[];
    porcentagemUso: number;
    porcentagemLinha: number[];
  }[];
}

export function ModalTiposMaisCalculados({
  isOpen,
  onClose,
  title,
  data,
}: ModalListaProps) {
  return (
    <ModalLista
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      columns={[
        {
          field: "codigo",
          headerName: "Tipo",
          sortable: false,
          flex: 0.1,
        },
        {
          field: "linha",
          headerName: "Linha",
          sortable: false,
          flex: 0.2,
          renderCell: (params) => {
            const linhas = params.row.linha as string[];
            return linhas.map((item) => {
              return (
                <>
                  {item}
                  <br />
                </>
              );
            });
          },
        },
        {
          field: "porcentagemLinha",
          headerName: "porcentagem da Linha",
          sortable: false,
          flex: 0.2,
          renderCell: (params) => {
            const linhas = params.row.porcentagemLinha as string[];
            return linhas.map((item) => {
              return (
                <>
                  {item}
                  <br />
                </>
              );
            });
          },
        },
        {
          field: "porcentagemUso",
          headerName: "% uso",
          sortable: false,
          flex: 0.1,
        },
      ]}
      rows={data.map((d, index) => {
        return {
          ...d,
          id: index + 1,
        };
      })}
    />
  );
}
