import { Stack } from "@mui/material";
import { ModalLista } from "./ModalLista";

interface ModalListaProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  data: {
    codigo: string;
    percentual: number;
  }[];
}

export function ModalPerfisMaisCalculados({
  isOpen,
  onClose,
  title,
  data,
}: ModalListaProps) {
  return (
    <ModalLista
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      columns={[
        {
          field: "codigo",
          headerName: "Código",
          sortable: false,
          flex: 1,
        },
        {
          field: "percentual",
          headerName: "% em orçamentos calculados",
          sortable: false,
          flex: 0.2,
          renderCell: (params) => {
            return (
              <Stack width={"100%"} display={"flex"} alignItems={"flex-end"}>
                {params.row.percentual}
              </Stack>
            );
          },
        },
      ]}
      rows={data.map((d, index) => {
        return {
          ...d,
          id: index + 1,
        };
      })}
    />
  );
}
